<template>

  <div class="container user-section">
		<div class="row">
			<div class="col-12">

        <b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>

        <b-alert variant="danger" :show="error? true : false">{{ error }}</b-alert>

        <div class="row px-2" v-if="!loading && visible > 0">
          <div class="col-12 col-md-6 col-lg-4" v-for="(item, index) in items" :key="item.id" >
            <div class="block bulletin" :id="['bulletin-' + item.id]" v-on:click="open(index)" :class="[item.message.length < 200 ? 'always-open' : (item.open == true ? 'open' : 'closed') ]">
              <div class="content">
                <h3>{{ item.name }}</h3>
                <p>{{ item.message }}</p>
              </div>
            </div>
          </div>
        </div>

				<b-alert class="my-3" variant="info" :show="!loading && items.length == 0">Tällä hetkellä ei ole yhtään tiedotetta.</b-alert>

				<ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 1">
					<li v-for="n in pages" :key="n" class="page-item" :class="[page == (n-1) ? 'active' : '']"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
				</ul>

			</div>
		</div>
	</div>

</template>

<script>
export default {
  data: function () {
		return {
			loading: true,
			error: false,
			items: [],
			visible: 0,
			perpage: 20,
			pages: 0,
			page: 0
		}
	},
	created: function () {
		var vm = this;
    vm.load();
	},
	methods: {
		load: function () {
			var vm = this;
			vm.loading = true;
			this.axios.get('/api/bulletins?site=' + vm.$parent.$parent.current_site + '&limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage))
			.then(function (response) {
				if(response.status == 200) {
					vm.loading = false;
					vm.pages = Math.ceil(response.data.total / vm.perpage);
					var items = response.data.results;
          Object.keys(items).forEach(function(key){ items[key].open = false; });
          vm.items = items;
					vm.visible = response.data.results.length;
				} else {
					vm.error = response.data.message;
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.items = [];
				vm.pages = 0;
				vm.loading = false;
			})
		},
		showpage: function (id) {
			this.page = id;
		},
    open: function(index) {
      if(this.items[index].open == true) this.items[index].open = false;
			else this.items[index].open = true;
    }
	},
	watch: {
		page: function () {
			this.load();
		},
    '$parent.$parent.current_site': function() {
      this.load();
    }
	}
}
</script>
