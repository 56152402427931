<template>

  <div class="container user-section">
		<div class="row">
			<div class="col-12">

        <b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>

        <b-alert variant="danger" :show="error? true : false">{{ error }}</b-alert>

        <b-overlay variant="white" :show="sending" no-wrap></b-overlay>

        <b-overlay variant="white" :show="uploading" no-wrap>
          <template #overlay>
            <div class="text-center text-dark p-4">
              <div class="mb-3">Siirretään tiedostoja...</div>
                <b-progress
                  min="1"
                  max="100"
                  :value="progress"
                  variant="success"
                  height="10px"
                  class="mx-n4 rounded-0"
                ></b-progress>
              </div>
            </template>
        </b-overlay>

        <div class="row pb-4 px-4" v-if="mode == 'list'">
          <div class="col-8 pt-1">
            <input type="text" v-model="search" class="form-control form-control-sm" placeholder="Hae nimellä" />
          </div>
          <div class="col-4 text-right">
            <button type="button" class="btn btn-sm btn-primary" v-on:click="load()">Hae</button>
          </div>
        </div>

        <div class="row px-2" v-if="visible > 0 && mode == 'list'">
          <div class="col-12 col-md-6 col-lg-4" v-for="item in items" :key="item.id">
            <div class="block ticket site" :id="['site-' + item.id]">
              <div class="content">

                <h3 class="my-3">{{ item.name }}</h3>
                <p class="mb-2">{{ item.address }}</p>

                <span class="open" v-on:click="show(item)">Näytä</span>

              </div>
            </div>
          </div>
        </div>

        <div class="row ticket-details" v-if="mode == 'view'">
          <div class="col-12">

            <div class="form-group pb-3 small-tabs">
              <a class="btn btn-secondary" v-on:click="mode = 'list'"><i class="fas fa-arrow-circle-left"></i> Takaisin</a>
            </div>

            <h1 class="mb-2">{{ site_name }}</h1>

            <p class="mb-4" v-html="site_info"></p>

            <h3>Muistiot</h3>
            <b-overlay variant="white" :show="memos_loading" no-wrap></b-overlay>

            <div class="d-none"><input class="d-block mt-2" type="file" ref="file" @change="selectFile" /></div>

            <div class="memos position-relative">
              <div class="alert alert-info" v-if="memos.length == 0">Kohteella ei ole muistioita.</div>

              <b-overlay :show="image_memoid > 0" variant="white" no-wrap>
                <template #overlay>
                  <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label" class="text-center dialog-mw" >
                    <p><strong id="form-confirm-label">Lisää kuva</strong></p>
                    <div class="form-group mobile">
                      <label class="form-label">Kuvan otsikko</label>
                      <input type="text" class="form-control" v-model="image_title" />
                    </div>
                    <div class="form-group">
                      <button type="button" class="btn btn-secondary" @click.stop.prevent="$refs.file.click()">{{ image_filename ? image_filename : 'Valitse tiedosto' }}</button>
                    </div>
                    <div class="text-center">
                      <b-button variant="outline-danger" class="mr-3" @click="image_memoid = 0">Peruuta</b-button>
                      <b-button variant="outline-success" @click="saveMemoImage()">TALLENNA</b-button>
                    </div>
                  </div>
                </template>
              </b-overlay>

              <div class="accordion" role="tablist">

                <b-card no-body v-for="(memo) in memos" :key="memo.id">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle="'accordion-' + memo.id" variant="primary">{{ memo.title }} {{ memo.created | formatDate }}</b-button>
                  </b-card-header>
                  <b-collapse :id="'accordion-' + memo.id" accordion="my-accordion" role="tabpanel">
                    <b-card-body>

                      <p><strong>Muistion sisältö</strong></p>
                      <p class="pre-wrap" v-html="memo.content"></p>
                      <p class="small">{{ memo.updated_by_name }} - {{ memo.updated | formatDateTimePretty }}</p>

                      <div class="row file-list" v-if="typeof memo.files != 'undefined' && memo.files.length > 0">
                        <div class="col-12"><p><strong>Kuvat</strong></p></div>
                        <div class="col-6 file" v-for="(file) in memo.files" :key="file.id" >
                          <img :src="['/api/sitefiles/' + file.id]" :alt="file.filename" v-on:click="showMemoImage(file)" />
                          <p class="blue small"><strong>{{ file.title }}</strong><br/>{{ file.fullname }}<br/>{{ file.created | formatDateTime }}</p>
                        </div>
                      </div>

                      <p class="mt-3 mb-0"><span class="cursor-pointer" v-on:click="image_memoid = memo.id"><i class="fas fa-images"></i> Lisää kuva</span> <span class="ml-2 cursor-pointer" v-on:click="editMemo(memo)"><i class="fas fa-edit"></i> Muokkaa</span></p>

                    </b-card-body>
                  </b-collapse>
                </b-card>

              </div>

            </div>

            <div class="form-group mt-4" ref="memoForm">
              <h3 v-if="typeof memo_data.id == 'undefined'">Uusi muistio</h3>
              <h3 v-if="typeof memo_data.id != 'undefined'">Muokkaa muistiota #{{ memo_data.id }}</h3>
              <div class="form-group mobile mt-3">
                <label class="form-label">Otsikko</label>
                <input type="text" v-model="memo_data.title" class="form-control" />
              </div>
              <div class="form-group mobile">
                <label class="form-label">Sisältö</label>
                <textarea v-model="memo_data.content" class="form-control"></textarea>
              </div>
              <div class="form-group text-right">
                <button type="button" class="btn btn-secondary ml-2" v-if="typeof memo_data.id != 'undefined'" v-on:click="newMemo()">PERUUTA</button>
                <button type="button" class="btn btn-success ml-2" v-on:click="saveMemo()"><i class="fas fa-save"></i> TALLENNA</button>
              </div>
            </div>

          </div>
        </div>

        <b-modal id="image-modal" size="xl" centered v-model="imageModal"><img :src="imageModalImg" /><p class="mt-4" v-html="imageModalInfo"></p></b-modal>

				<b-alert class="my-3" variant="info" :show="!loading && items.length == 0 && mode == 'list'">Ei kohteita.</b-alert>

				<ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 1 && mode == 'list'">
					<li v-for="n in pages" :key="n" class="page-item" :class="[page == (n-1) ? 'active' : '']"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
				</ul>

			</div>
		</div>
	</div>

</template>

<script>
export default {
  data: function () {
		return {
			loading: true,
      sending: false,
			error: false,
      search: '',
      tab: 'info',
      mode: 'list',
      site_id: 0,
      site_name: '',
      site_info: '',
			items: [],
      uploading: false,
      progress: 0,
			visible: 0,
			perpage: 20,
			pages: 0,
			page: 0,
      imageModal: false,
      imageModalImg : '',
      imageModalInfo : '',
      memos_loading: false,
      memos: [],
      memo_data: { title: '', content: '' },
      image_title: '',
      image_memoid: 0,
      image_filename: ''
		}
	},
	created: function () {
		var vm = this;
    vm.load();
	},
	methods: {
		load: function () {
			var vm = this;
			vm.loading = true;
			this.axios.get('/api/sites?limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage) + '&search=' + vm.search)
			.then(function (response) {
				if(response.status == 200) {
					vm.loading = false;
					vm.pages = Math.ceil(response.data.total / vm.perpage);
          vm.items = response.data.results;
					vm.visible = response.data.results.length;
				} else {
					vm.error = response.data.message;
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.items = [];
				vm.pages = 0;
				vm.loading = false;
			})
		},
    show: function(item) {
      var vm = this;
      vm.site_id = item.id;
      vm.site_name = item.name;
      vm.site_info = item.business_id + '<br/>' + item.address;
      vm.mode = 'view';
      vm.loadMemos();
    },
    loadMemos: function () {
      var vm = this;
      vm.memos = [];
      vm.memos_loading = true;

      this.axios.get('/api/sitememos?site_id=' + vm.site_id)
      .then(function (response) {
        if(response.status == 200) {
          vm.memos = response.data.results;
          vm.memos_loading = false;
        } else {
          vm.$parent.makeToast('danger', 'Kohteen lataus', 'Epäonnistui!');
          vm.memos = [];
          vm.memos_loading = false;
        }
      })
      .catch(function (error) {
        console.log(error);
        vm.$parent.makeToast('danger', 'Kohteen lataus', 'Epäonnistui!');
        vm.memos = [];
        vm.memos_loading = false;
      })
    },
    editMemo: function ( memo ) {
      var vm = this;
      vm.memo_data = { id: memo.id, title: memo.title, content: memo.content };

      const el = this.$refs.memoForm;
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    newMemo: function () {
      this.memo_data = { title: '', content: '' };
      this.image_memoid = 0;
    },
    saveMemo: function () {
			var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				var method = 'put';
				if(typeof vm.memo_data.id == 'undefined') {
          method = 'post';
          vm.memo_data.site_id = vm.site_id;
        }
				this.axios({
					method: method,
					url: '/api/sitememos',
					data: vm.memo_data
				})
				.then(function (response) {
					if(response.data.success) {
						vm.loadMemos();
            vm.newMemo();
						vm.$parent.makeToast('success', 'Muistion tallennus', 'Onnistui!');
					} else {
						vm.$parent.makeToast('warning', 'Muistion tallennus', 'Täytä pakolliset kentät!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Muistion tallennus', 'Epäonnistui!');
				})
			}
		},
		showpage: function (id) {
			this.page = id;
		},
    saveMemoImage: function() {
      var vm = this;

      let file = false;
      for( var i = 0; i < vm.$refs.file.files.length; i++ ){
				file = vm.$refs.file.files[i];
			}

      if(!file || vm.image_memoid == 0 || vm.image_memotitle == '') return;

      vm.uploading = true;
      vm.progress = 0;

      let formData = new FormData();
      formData.append('memo_id', vm.image_memoid);
      formData.append('title', vm.image_title);
      formData.append('file', file);

      this.axios.post('/api/sitefiles', formData, {
				headers: {
          'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: progressEvent => {
					vm.progress = Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 );
				}
			})
			.then(function (response) {
        if(response.data.success) {
          vm.$parent.makeToast('success', 'Tiedostojen siirto', 'Onnistui!');
          vm.uploading = false;
          vm.image_title = '';
          vm.$refs.file.value = null;
          vm.selectFile();
          vm.loadMemos();
          vm.image_memoid = 0;
        } else {
          vm.$parent.makeToast('danger', 'Tiedostojen siirto', 'Epäonnistui!');
          vm.uploading = false;
        }
			})
			.catch(function () {
				vm.$parent.makeToast('danger', 'Tiedostojen siirto', 'Epäonnistui!');
				vm.uploading = false;
			});

    },
    showMemoImage: function(image) {
      var vm = this;
      vm.imageModalImg = ['/api/sitefiles/' + image.id];
      vm.imageModalInfo = '<strong>' + image.title + '</strong><br/>' + image.fullname + ' - ' + vm.$options.filters.formatDateTime(image.created);
      vm.imageModal = true;
    },
    selectFile: function () {
      this.image_filename = false;
      if(typeof this.$refs.file.files[0] != 'undefined') this.image_filename = this.$refs.file.files[0].name;
    }
	},
	watch: {
		page: function () {
			this.load();
		}
	}
}
</script>
